


























































































import GetFilesUrlService from '@/includes/services/GetFilesUrlService'

type AnswerResult = {
  name: string
  value: any
  type: FormFieldType
}

import { FormService } from '@/includes/services/FormService'
import { Form, FormAnswer, FormAnswerFields, FormFields, FormFieldType } from '@/includes/types/Form.types'
import { errorNotification } from '@/includes/services/NotificationService'

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import FileApi from 'piramis-base-components/src/components/File/logic/file-api'
import ZoomableImage from 'piramis-base-components/src/components/ZoomableImage/ZoomableImage.vue'

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import moment from 'moment'

@Component({
  components: {
    UserMainInfo,
    ZoomableImage
  },
  data() {
    return {
      FormFieldType
    }
  }
})
export default class FormAnswers extends Vue {
  @Prop({ type:Object }) form!:Required<Form>

  limit = 10

  offset = 0

  answers: Array<FormAnswer> = []

  loading = false

  answersEnd = false

  fileApi = new FileApi('api.presscode.app/cabinet')

  getSaveUrl(descriptor:string):void {
    GetFilesUrlService.getFilesUrl('tg', descriptor, {
      'target': this.$store.state.boardsState.activeBoard!.board
    })
  }

  filePreview(descriptor:string): string {
    return `${ this.fileApi.getApiUrl() }/getfilepreview?file_descriptor=${ descriptor }&target=${ this.$store.state.boardsState.activeBoard!.board }`
  }

  telegramLink(userLogin:string):string  {
    return `https://t.me/${ userLogin }`
  }

  momentDate(dateTime:string):string {
    moment.locale(this.$i18n.locale)

    return moment(dateTime).format('LLL')
  }

  getAnswerValue(answer, field: FormFields) {
    if (field.id) {
      const value = answer[field.id]

      if (field.type === FormFieldType.Multiselect) {
        const readableAnswers = value.map(i => field.options[i])

        if (readableAnswers.length > 0) {
          return readableAnswers.length === 1 ? readableAnswers[0] : readableAnswers.join(', ')
        }

        return '-'
      }

      if (field.type === FormFieldType.Select) {
        return field.options[value] ?? '-'
      }

      return value
    }
  }

  parseAnswerDataFields(answers: FormAnswerFields):Array<AnswerResult> {
    const fields:Array<AnswerResult> = []

    this.form.config.fields.forEach(field => {
      fields.push({
        type: field.type,
        name: field.name,
        value: this.getAnswerValue(answers.values, field)
      })
    })

    return fields
  }

  loadMore():void {
    this.requestAnswers((this.answers.length - 1) + this.limit)
  }

  requestAnswers(offset:number = 0):void {
    this.loading = true

    FormService.getFormAnswers('tg', {
      board_key: this.$store.state.boardsState.activeBoard!.board,
      key: this.form.key,
      limit: this.limit,
      offset,
    })
      .then(({ items }) => {
        if (items.length) {
          this.answers.push(...items)
        } else {
          this.answersEnd = true
        }
      })
      .catch(errorNotification)
      .finally(() => {
        this.loading = false
      })
  }

  mounted():void {
    this.requestAnswers()
  }
}
