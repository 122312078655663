




















































































import PageTitle from '@/components/PageTitle.vue'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction';
import { FormService } from '@/includes/services/FormService'
import { Form } from '@/includes/types/Form.types'
import DrawerWidthMixin from '@/includes/DrawerWidthMixin'
import FormAnswers from '@/components/Forms/FormAnswers.vue';
import { errorNotification, successNotification } from '@/includes/services/NotificationService'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    PageTitle,
    FormAnswers,
    EmptyData
  },
  data() {
    return {
      BaseItemViewAction
    }
  }
})
export default class FormList extends Mixins(DrawerWidthMixin) {
  openAnswers = false

  selectedForm: null | Required<Form> = null

  get forms() {
    return this.$store.state.formsState.forms
  }

  get sortedForms():Array<Required<Form>> {
    if (this.forms) {
      return this.forms.sort((a, b) => b.answers - a.answers)
    }

    return []
  }

  cutIfLarge(str:string):string {
    if (str.length > 150) {
      return str.slice(0, 150) + "..."
    }

    return str
  }

  handleTagClick(formKey:Required<Form>):void {
    this.selectedForm = formKey

    this.openAnswers = true
  }

  handleDrawerClose():void {
    this.selectedForm = null

    this.openAnswers = false
  }

  gotoForm(actionType: BaseItemViewAction, formKey?: string):void {
    this.$router.push({
      name: 'Full_form',
      params: {
        actionType,
        ... formKey ? { formKey } : null,
      }
    })
  }

  deleteFormWarn(key:string):void {
    this.$confirm({
      title: this.$t('delete_form_warn_title').toString(),
      content: this.$t('delete_form_warn_text').toString(),
      okText: this.$t('confirm').toString(),
      cancelText: this.$t('cancel').toString(),
      onOk: () => this.deleteForm(key)
    })
  }

  deleteForm(key:string):void {
    this.$store.dispatch('deleteForm', key)
  }

  mounted():void {
    if (!this.$store.state.formsState.forms) {
      this.$baseTemplate.loader.open()

      this.$store.dispatch('getForms')
        .finally(() => this.$baseTemplate.loader.close())
    }

    this.initializeSidebarWidth(800)
  }
}
